* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Font Family */

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Regular.woff2) format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Bold.woff2) format("woff2");
    font-weight: 700;
}

.about-section .container {
    width: 100%;
    max-width: 700px;
    margin: 0px auto;
    padding: 0px 15px;
}

body {
    font-family: "Gilroy", sans-serif;
    color: #212124;
}

/* About Section  */

.about-background {
    background: url(../images/marketplace.jpg) no-repeat center center/cover;
    width: 100%;
    height: 520px;
}

.about-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.about-section h2 {
    font-size: 42px;
    font-family: "Gilroy";
    margin: 0 0 30px;
}

.about-section h3 {
    font-size: 24px;
    margin: 30px 0 20px;
}

.about-section p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 20px;
}

.investors-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px;
}

.investors-flex > div {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(200px - 10px);
    flex: 1 1 calc(200px - 10px);
    max-width: 200px;
}

.investors-flex > div img {
    width: 100%;
    max-width: 200px;
}

.about-section .link {
    padding: 0 0 20px;
}

.about-section .link a {
    color: #212124;
}

.about-section a {
    text-decoration: underline;
}

.about-section span {
    color: #868b94;
}

.about-section .para {
    margin: 0 0 10px;
}

.about-section .para a {
    color: #212124;
}

.about-section .para span {
    color: inherit;
}
