* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Font Family */

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Regular.woff2) format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Bold.woff2) format("woff2");
    font-weight: 700;
}

/* Variables */

:root {
    --primaryColor: #17a525;
    --secondaryColor: #00b493;
}

html {
    -webkit-tap-highlight-color: transparent;
}

body {
    font-family: "Gilroy", sans-serif !important;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

a,
button {
    display: inline-block;
    font: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

svg {
    max-width: 100%;
    height: auto;
}

svg {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 5px;
}

section {
    padding: 5rem 0;
    position: relative;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px 15px;
}

/* Typography */

h1 {
    font-size: 72px;
    margin: 0 0 30px;
}

h2 {
    font-size: 56px;
}

h3 {
    font-size: 42px;
    margin: 0 0 30px;
}

h3 span {
    color: var(--primaryColor);
}

.secondary-heading span {
    color: var(--secondaryColor);
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 24px;
    color: #212124;
}

h6 {
    font-size: 16px;
    color: #212124;
    opacity: 0.5;
}

p {
    font-size: 18px;
    color: #212124;
    font-weight: 600;
    margin: 20px 0 0;
    line-height: 25px;
}

p span {
    color: var(--primaryColor);
}

.para-2 {
    font-size: 16px;
}

/* Navigation Bar Styles */

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-transition: 0.25s ease;
    -o-transition: 0.25s ease;
    transition: 0.25s ease;
}

.nav-container {
    position: relative;
    padding: 1rem 0;
    background-color: #fff;
    -webkit-transition: padding 0.25s ease;
    -o-transition: padding 0.25s ease;
    transition: padding 0.25s ease;
}

nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5rem;
}

.logo {
    width: 50px;
    overflow: hidden;
}

.nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    list-style: none;
    font-size: 1rem;
    font-weight: 900;
}

.nav-list li {
    -webkit-transition: opacity 0.35s ease;
    -o-transition: opacity 0.35s ease;
    transition: opacity 0.35s ease;
}

.nav-social {
    width: 24px;
}

.nav-list li:hover {
    opacity: 0.75;
}

/* Hamburger Styles */

.hamburger {
    display: none;
}

.hamburger {
    width: 26px;
    height: 32px;
    margin-top: 10px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
    top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
    top: 8px;
}

.hamburger span:nth-child(4) {
    top: 16px;
}

header.open .hamburger span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

header.open .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

header.open .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

header.open .hamburger span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* Hero Section */

.hero-section {
    background-color: #fbf7f2;
    min-width: 100%;
    min-height: 780px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hero-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
}

.hero-text {
    width: 60%;
    height: 100%;
}

.mockup-img {
    position: relative;
    width: 40%;
    max-width: 300px;
    margin: auto;
    border: 5px solid var(--primaryColor);
    border-radius: 25px;
    overflow: hidden;
}

.mockup-img > img {
    width: 100%;
    margin: 0 auto;
}

.btn {
    padding: 15px 35px;
    background-color: var(--primaryColor);
    border: none;
    color: #ffffff;
    border-radius: 10px;
    font-size: 16px;
    margin: 0px 5px;
    font-weight: 600;
    -webkit-transition: opacity 0.25s ease;
    -o-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.btn svg {
    margin-right: 5px;
}

.btn:hover {
    opacity: 0.8;
}

.btn.fixed-btn {
    display: none;
    position: fixed;
    width: 95%;
    left: 50%;
    bottom: 10px;
    margin: 0;
    z-index: 999999;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}

.btn.mobile {
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.mockup-items {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 120px);
}

.mockup-items > img {
    margin: 5px 0;
}

/* Hero Section End */

/* Popular Section */

.popular-content h4 {
    font-size: 1.5em;
    text-align: center;
}

.popular-content a {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4rem auto 0;
    text-decoration: underline;
    font-weight: 700;
    color: var(--primaryColor);
}

.popular-products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 3rem;
}

.popular-product-img {
    width: 170px;
    height: 170px;
    border-radius: 5px;
    overflow: hidden;
}

.popular-product-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.product-price {
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 10px;
}

.product-location {
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
}

/* Popular Section End */

/* Market Section */
.market-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 60px 0px;
    background: url(../images/marketplace.jpg) no-repeat top center/cover;
    height: 600px;
    width: 100%;
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
}

.market-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 165, 37, 0.4);
}

.market-content {
    z-index: 1;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 10px;
    color: #ffffff;
    text-align: center;
}

.market-content img {
    display: inline-block;
    width: 25px;
    vertical-align: middle;
    margin-right: 0px;
    margin-bottom: 4px;
}

/* Market Section End */

/* Feature Section */

.feature-section {
    background-color: #fbf7f2;
}

.feature-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.feature-text {
    width: 60%;
}

.mockup-review {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 100%;
    height: calc(100% - 200px);
    overflow: hidden;
}

.feature-2 {
    background-color: #e8faf6;
}

.feature-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
}

.feature-icon > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.feature-icon a {
    margin-right: 5px;
}

.messages {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 70px;
    width: 100%;
    height: calc(100% - 200px);
    overflow: hidden;
}

.messages > img {
    margin: 2px 0;
}

/* Feature Section End */

/*  Free Section Start */

.free-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.hand-img img {
    width: 100%;
    max-width: 100px;
}

/* Free Section End */

/* Testimonial Section */

.testimonial-section {
    background-color: #fbf7f2;
}

.testimonial-content > h5 {
    text-align: center;
}

.testimonial-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 30px;
    padding: 50px 0;
}

.testimonial-flex > div {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    padding: 53px 36px 36px;
    background-color: #ffffff;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.testimonial-box > div:last-child {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.testimonial-author > div:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0;
}

.testimonial-author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

/* Testimonial Section End */

/* Started Section */

.started-content {
    text-align: center;
}

.started-text {
    margin: 20px 0;
}

.started-img {
    max-width: 200px;
    margin: 0 auto;
}

.started-content .btn {
    background-color: #eaebee;
    color: #212124;
}

/* Started Section End */

/* Items Section */

.items-section .container {
    max-width: 980px;
}

.items-section h3 {
    font-size: 2rem;
    text-align: center;
}

.items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 3rem;
}

.item-filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 3rem;
}

.item-filters > select {
    width: 180px;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.75rem 1.25rem;
    border: 1px solid #e9ecef;
    border-radius: 5px;
}

.item p {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin: 10px 0;
    line-height: 1em;
}

.item-img {
    width: 200px;
    height: 200px;
    border-radius: 20px;
    margin-bottom: 15px;
    overflow: hidden;
}

.items-product-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.item-name {
    font-size: 1rem;
    font-weight: 400;
}

.item-price {
    font-size: 1rem;
    font-weight: 700;
}

.item-location {
    font-weight: 500;
    font-size: 0.875rem;
}

.item-info {
    font-weight: 400;
    font-size: 13px;
    color: #868eb1;
}

.products-page .btn.fixed-btn {
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-radius: 0;
}

/* More Section */

.more-section {
    background-color: #f8f9fa;
}

.more-content {
    text-align: center;
}

.more-content h5 {
    font-size: 1.4rem;
    margin-bottom: 3rem;
}

.more-content p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

/* Footer */

footer {
    padding: 4rem 0 8rem;
    border-top: 1px solid #dcdee3;
}

.footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    gap: 2rem;
    font-size: 0.875rem;
}

.footer-social {
    width: 28px;
    height: auto;
}

.copyright {
    font-weight: 400;
    color: #868b94;
    font-size: 0.75rem;
    margin-top: 3rem;
}

/* Footer End */

/* Media Queries */

@media (max-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 960px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 720px;
    }

    .hero-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .feature-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
    }

    .testimonial-flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .hero-content h1 br:nth-child(2) {
        display: none;
    }

    .feature-content h3 br {
        display: none;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 540px;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
        margin: 15px 0 0;
    }

    h3 {
        font-size: 20px;
    }

    /* Navigation Bar */

    .hamburger {
        display: block;
    }

    header,
    .nav-container {
        background-color: #fff;
    }

    .nav-container,
    header.scroll .nav-container {
        padding: 0.75rem 0;
    }

    .logo {
        width: 50px;
    }

    .nav-list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 20px 0;
        background-color: #fff;
        border-top: 1px solid #fff;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-transition: -webkit-transform 0.5s, -webkit-box-shadow 0.5s;
        transition: -webkit-transform 0.5s, -webkit-box-shadow 0.5s;
        -o-transition: transform 0.5s, box-shadow 0.5s;
        transition: transform 0.5s, box-shadow 0.5s;
        transition: transform 0.5s, box-shadow 0.5s, -webkit-transform 0.5s,
            -webkit-box-shadow 0.5s;
        transition: transform 0.5s, box-shadow 0.5s, -webkit-transform 0.5s,
            -webkit-box-shadow 0.5s;
        border-top: 1px solid #868eb1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        z-index: -1;
    }

    header.open .nav-list {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    .hero-text {
        text-align: center;
    }

    .btn {
        display: none;
    }

    .feature-text {
        text-align: center;
    }

    .mockup-img {
        width: 95%;
    }

    .feature-icon {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
    }

    .free-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .testimonial-flex > div {
        padding: 30px;
    }

    .hero-text {
        width: 100%;
    }

    .feature-text {
        width: 100%;
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .feature-icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .btn.mobile,
    .btn.fixed-btn {
        display: block;
    }

    .footer-content,
    .footer-content .footer-list:first-child {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 2rem;
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    .hero-content {
        gap: 0;
    }

    .item-filters {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .item-filters > select {
        width: 100%;
    }

    .items {
        gap: 1.5rem;
    }

    .item {
        max-width: 150px;
    }

    .item-img {
        width: 150px;
        height: 150px;
    }

    .items-content h3 {
        font-size: 1.5rem;
    }

    .popular-product-img {
        width: 140px;
        height: 140px;
        border-radius: 5px;
        overflow: hidden;
    }
}
